const discover = [
  {
    name: "Track 1",
    // description: "This is a cool url 1. Everything you need to know.",
    imageUrl: "john.jpg",
    audioUrl: "https://www.facebook.com/",
  },
  {
    name: "Track 2",
    // description: "This is a cool url 2. Everything you need to know.",
    imageUrl: "john.jpg",
    audioUrl: "https://www.linkedin.com/",
  },
  {
    name: "Track 3",
    // description: "This is a cool url 2. Everything you need to know.",
    imageUrl: "john.jpg",
    audioUrl: "https://www.linkedin.com/",
  },
  {
    name: "Track 4",
    // description: "This is a cool url 2. Everything you need to know.",
    imageUrl: "john.jpg",
    audioUrl: "https://www.linkedin.com/",
  },
  {
    name: "Track 5",
    // description: "This is a cool url 2. Everything you need to know.",
    imageUrl: "john.jpg",
    audioUrl: "https://www.linkedin.com/",
  },
];

module.exports = discover;
